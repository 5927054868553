import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFormLabel = lazy(
  () => import("./form-label-B4GsvDY-.js").then((module) => ({
    default: module.FormLabel
  }))
);
function FormLabel({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormLabel, { ...props });
}
const useFormLabelContent = () => {
  return useMemo(
    () => ({
      formLabel: {
        component: FormLabel
      }
    }),
    []
  );
};
export {
  LazyFormLabel as FormLabel,
  useFormLabelContent
};
